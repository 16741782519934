import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";  // Import React Router
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import PrivacyPolicy from "./components/privacy"; // Import PrivacyPolicy component
import "./App.css";
import CookieConsent from "./components/consent";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function enableGoogleAnalytics() {
  // Initialize Google Analytics only if consent was given
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "UA-228697929-1");
}

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);

    const consent = localStorage.getItem("cookieConsent");
    if (consent === "true") {
      enableGoogleAnalytics();
    }
  }, []);

  return (
    <Router>
      <div>
        {/* Add the custom CookieConsent banner */}
        <CookieConsent onAccept={enableGoogleAnalytics} />

        <Switch>
          <Route path="/privacy-policy">
            <Navigation />
            <Header data={landingPageData.Header} />
            <PrivacyPolicy />
          </Route>
          <Route path="/">
            <Navigation />
            <Header data={landingPageData.Header} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Contact data={landingPageData.Contact} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
