import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
const CookieConsent = ({ onAccept }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already given consent
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
    if (onAccept) onAccept();
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div style={styles.banner}>
      <div style={styles.textContainer}>
        <p style={styles.text}>
          Acest site folosește cookie-uri pentru a vă îmbunătăți experiența. Făcând clic pe <strong>"Accept"</strong>, sunteți de acord cu utilizarea cookie-urilor.{" "}
          <Link to="/privacy-policy" style={styles.link}>
            Află mai multe
          </Link>.
        </p>
      </div>
      <div style={styles.buttonContainer}>
        <button onClick={handleAccept} style={styles.acceptButton}>
          Accept
        </button>
        <button onClick={handleDecline} style={styles.declineButton}>
          Refuz
        </button>
      </div>
    </div>
  );
};

const styles = {
    banner: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      backgroundColor: "#f8f8f8",
      boxShadow: "0 -1px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 1000,
      padding: "20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "'Open Sans', sans-serif",
    },
    textContainer: {
      flex: 1,
    },
    text: {
      fontSize: "14px",
      color: "#333",
      margin: 0,
    },
    link: {
      textDecoration: "underline",
      color: "#007bff",
      cursor: "pointer",
    },
    buttonContainer: {
      display: "flex",
      gap: "10px",
    },
    acceptButton: {
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      padding: "10px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "14px",
    },
    declineButton: {
      backgroundColor: "#ddd",
      color: "#333",
      border: "none",
      padding: "10px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "14px",
    },
  };
  
export default CookieConsent;
